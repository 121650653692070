@media (min-width: 360px) {
    .heart {
        width: 80vw;
        height: 72px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(29, 33, 41, 1);
    }
    .heart .menu {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .heart .ant-menu-item a {
        font-size: 16px;
        font-weight: 400;
    }
    .heart .img {
        width: 82px;
        height: 36px;
        margin-right: 40px;
    }
    .heart .menu ul {
        height: 100%;
        border: none;
        display: flex;
        align-items: center;
    }
    .heart .menu .ant-menu-item-selected::after {
        content: none;
    }
    .heart .menu .ant-menu-item:hover::after {
        border: none;
    }
    .heart .menu .ant-menu-item::after {
        border: none;
    }
    .heart .userAndMenu {
        display: flex;
        align-items: center;
        font-size: 22px;
    }
    .heart .i18n-buts {
        width: 160px;
        margin-right: 20px;
    }
    .heart .i18n-buts label {
        width: 80px;
        text-align: center;
    }
}