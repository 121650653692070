@media (min-width: 360px) {
    .footer-heart {
        width: 80vw;
        height: 258px;
        margin: auto;
        padding: 80px 0;
    }
    
    .footer-heart .divider {
        background-color: rgba(255, 255, 255, 0.3);
        margin: 0;
        margin-bottom: 21px;
    }
    
    .footer-heart .center {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    
    .footer-heart .center img {
        width: 250px;
        height: 32px;
    }
    
    .footer-heart .center .center-center {
        width: 300px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
    }
    
    .footer-heart .center-center .but-link {
        height: 28px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(255, 255, 255, 1);
    }
    
    .footer-heart .center .center-right {
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    
    .footer-heart .center-right img {
        width: 20px;
        height: 20px;
    }

    .footer-heart .bottom {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
    }
}