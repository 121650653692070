@media (min-width: 360px) {
    .face-swipe {
        width: 80vw;
        margin: 30px auto 80px;
    }
    .face-swipe .face-swipe-header {
        width: 100%;
        height: 40px;
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
    }
    .face-swipe .face-swipe-header > button {
        width: 40px;
        height: 40px;
        margin-right: 20px;
    }
    
    .face-swipe .face-swipe-box{
        display: flex;
        justify-content: space-between;
    }
    
    .face-swipe .prompt {
        width: 27vw;
        min-height: 400px;
        border-radius: 16px;
        border: 1px solid rgba(218, 220, 224, 1);
        box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
        margin-right: 40px;
        position: relative;
        align-self: flex-start;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .face-swipe .title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .face-swipe .title span:nth-of-type(1) {
        font-size: 20px;
        font-weight: 600;
        line-height: 80px;
        margin-left: 20px;
    }
    .face-swipe .title span:nth-of-type(2) {
        font-size: 20px;
        color: rgba(128, 128, 128, .5);
        margin-left: 10px;
    }
    .face-swipe .prompt .but {
        width: 90%;
        height: 50px;
        margin: 0 11px;
        position: absolute;
        bottom: 20px;
        background-color: rgba(0, 0, 0, 1);
    }
    .face-swipe .prompt .but:disabled {
        background-color: rgba(255, 255, 255, 1);
    }
    .face-swipe .prompt .but:disabled:hover {
        background-color: rgba(255, 255, 255, 1);
    }
    .face-swipe .prompt .but:hover {
        background-color: rgba(0, 0, 0, .8);
    }
    .face-swipe .output-image {
        flex: 1;
        position: relative;
    }
    .face-swipe .output-image .output-image-bfc {
        width: 100%;
        height: 752px;
        border-radius: 16px;
        padding: 16px;
        border: 1px solid rgba(218, 220, 224, 1);
        box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        align-self: flex-start;
    }
    .face-swipe .output-image .img {
        max-width: calc(50vw - 32px);
        max-height: 720px;
    }
    .face-swipe .output-image .loading-box {
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .face-swipe .output-image .loading {
        width: 32px;
        height: 32px;
    }
    .face-swipe .output-image .text {
        height: 32px;
        line-height: 32px;
        margin-top: 20px;
        color: rgba(128, 128, 128, .5);
    }
    .face-swipe .output-image .but {
        position: absolute;
        bottom: -50px;
        right: 0;
    }
    
    .face-swipe .form {
        width: 93%;
        display: flex;
        justify-content: space-evenly;
    }
    .face-swipe .form .upload-pose {
        width: 40%;
    }
    .face-swipe .upload-pose img {
        width: 100%;
    }
    .face-swipe .form .upload-face {
        width: 40%;
    }
    .face-swipe .upload-face img {
        width: 100%;
    }
    
    .face-swipe .ant-upload {
        border: none !important;
    }
    .face-swipe .ant-btn[disabled] {
        background-color: white
    }
    .face-swipe .prompt .hint {
        width: 93%;
        display: flex;
        justify-content: space-between;
        margin: 20px 0 100px 0;
    }
    .face-swipe .hint > div{
        width: 40%;
        text-align: center;
        margin: 0 20px;
        font-size: 20px;
    }
    .face-swipe .ant-upload-list,.ant-upload-list-text{
        display: none;
    }
    
    .face-swipe .ant-upload-drag-icon span svg {
        color: rgba(128, 128, 128, 1);
    }
}