@media (min-width: 360px) {
    .api-content-one {
        width: 100%;
        display: flex;
        justify-content: space-between;
        color: rgba(29, 33, 41, 1);
        margin: 80px 0;
    }
    .api-content-one .api-content-one-left {
        flex: 1;
        padding-right: 20px;
    }
    .api-content-one .api-content-one-left > div:nth-child(1) {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
    }
    .api-content-one .api-content-one-left > div:nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 16px;
    }
    .api-content-one .api-content-one-left .introduction {
        border-radius: 16px;
        background-color: rgba(242, 243, 245, 1);
        margin-top: 32px;
        padding: 24px 32px;
    }
    .api-content-one .introduction > div:nth-child(1) {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }
    .api-content-one .introduction .list {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 19px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 24px;
    }
    .api-content-one .introduction .list > div {
        width: 324px;
        margin-bottom: 16px;
    }
    .api-content-one .list > div > span {
        margin-right: 20px;
    }


    .api-content-one .api-content-one-right {
        width: 360px;
    }
    .api-content-one .api-content-one-right > img:nth-child(1) {
        width: 100%;
        height: 190px;
        border-radius: 16px;
    }
    .api-content-one .api-content-one-right > div:nth-of-type(1) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 0;
    }
    .api-content-one .api-content-one-right > div:nth-of-type(1) > div:nth-of-type(1) {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
    }
    .api-content-one .api-content-one-right > div:nth-of-type(1) > div:nth-of-type(2) {
        height: 48px;
        width: 1px;
        background-color: rgba(134, 144, 156, 1);
    }
    .api-content-one .api-content-one-right .rate-box > div {
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
    }
    .api-content-one .api-content-one-right .but {
        width: 100%;
        height: 52px;
        font-size: 20px;
        font-weight: 400;
        border-radius: 16px;
    }
}