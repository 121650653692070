@media (min-width: 360px) {
    .text-style-image {
        width: 80vw;
        margin: 30px auto 80px;
    }
    .text-style-image .text-style-image-header {
        width: 100%;
        height: 40px;
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
    }
    .text-style-image .text-style-image-header > button {
        width: 40px;
        height: 40px;
        margin-right: 20px;
    }
    .text-style-image .text-style-image-box{
        display: flex;
    }
    
    .text-style-image .prompt {
        width: 27vw;
        min-height: 400px;
        border-radius: 16px;
        border: 1px solid rgba(218, 220, 224, 1);
        box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
        margin-right: 40px;
        position: relative;
        align-self: flex-start;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .text-style-image .title {
        width: 100%;
        height: 50px;
    }
    .text-style-image .up-down-title {
        height: 50px;
    }
    .text-style-image .title .up-icon {
        color: rgba(0, 0, 0, 1) !important;
    }
    .text-style-image .title span:nth-of-type(1) {
        font-size: 18px;
        font-weight: 500;
        line-height: 50px;
    }
    .text-style-image .title span:nth-of-type(2) {
        font-size: 20px;
        color: rgba(128, 128, 128, .5);
        margin-left: 10px;
    }
    .text-style-image .prompt .text-area {
        width: 100%;
        border-radius: 16px;
        border: 1px solid rgba(218, 220, 224, 1);
        padding: 4px;
    }
    .text-style-image .prompt .text-area .ant-input-clear-icon {
        position: absolute;
        top: 10px;
        right: 22px;
    }
    .text-style-image .prompt .text-area textarea {
        min-height: 100px;
    }
    
    .text-style-image .prompt .but {
        width: 93%;
        height: 50px;
        position: absolute;
        bottom: 20px;
        background-color: rgba(0, 0, 0, 1);
    }
    .text-style-image .prompt .but:disabled {
        background-color: rgba(255, 255, 255, 1);
    }
    .text-style-image .prompt .but:disabled:hover {
        background-color: rgba(255, 255, 255, 1);
    }
    .text-style-image .prompt .but:hover {
        background-color: rgba(0, 0, 0, .8);
    }
    
    .text-style-image .output-image {
        flex: 1;
        position: relative;
    }
    .text-style-image .output-image .output-image-bfc {
        width: 100%;
        height: 752px;
        border-radius: 16px;
        padding: 16px;
        border: 1px solid rgba(218, 220, 224, 1);
        box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        align-self: flex-start;
    }
    .text-style-image .output-image .img {
        max-width: calc(50vw - 32px);
        max-height: 720px;
    }
    .text-style-image .output-image .loading-box {
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .text-style-image .output-image .loading {
        width: 32px;
        height: 32px;
    }
    .text-style-image .output-image .text {
        height: 32px;
        line-height: 32px;
        margin-top: 20px;
        color: rgba(128, 128, 128, .5);
    }
    .text-style-image .output-image .but {
        position: absolute;
        bottom: -50px;
        right: 0;
    }
    .text-style-image .prompt .form {
        width: 93%;
        margin: 0 10px 100px 10px;
    }
    .text-style-image .prompt .style {
        width: 100%;
        height: 46px;
    }
    .text-style-image .prompt .style .ant-select-selector {
        border-radius: 16px;
        padding-left: 20px;
    }
    .text-style-image .text-style-image-box .ratio {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .text-style-image .ratio label {
        width: 110px;
        border-radius: 16px;
        margin-bottom: 20px;
        border: 1px solid currentColor;
        text-align: center;
    }
    .text-style-image .ratio label::before {
        content: none;
    }
}