.toc {
    width: 80vw;
    margin: 0 auto;
    padding: 16px;
    font-size: 14px;
}
.toc h1,h2,h3,h4,h5,h6,ul,li,p {
    margin: 0;
    padding: 0;
}
.toc .h1 {
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid rgba(228, 231, 231, 1);
    padding-bottom: 8px;
    margin-bottom: 16px;
}
.toc .h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}
.toc .i {
    display: inline-block;
    margin-bottom: 16px;
}
.toc .welcome {
    display: block;
    margin-bottom: 16px;
}
.toc p {
    margin-bottom: 16px;
}
.toc ul {
    padding-left: 40px;
    margin-bottom: 16px;
}
.toc .h3 {
    display: inline;
    font-weight: 600;
}