@media (min-width: 360px) {
    .contentThree {
        width: 100%;
        min-height: 100px;
        background-color: rgba(134, 144, 156, 1);
    }
    
    .contentThree .type-area {
        width: 80vw;
        margin: auto;
        overflow: hidden;
    }
    
    .contentThree .type-area .title {
        transform: scale(0);
        opacity: 0;
    }
    
    .contentThree .type-area .title div:nth-of-type(1) {
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        margin-top: 80px;
    }
    
    .contentThree .type-area .title div:nth-of-type(2) {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        margin-top: 16px;
    }
    
    .contentThree .type-area .but {
        width: 128px;
        height: 52px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.1);
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        font-size: 20px;
        line-height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 24px 0px 24px;
    }
    
    .contentThree .type-area .content {
        width: 80vw;
        margin: 40px 0;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
    }
    
    .contentThree .type-area .content-box-one {
        width: 39vw;
    }
    .contentThree .type-area .content-box-two {
        width: 39vw;
    }
    
    .contentThree .content .card {
        width: 100%;
        margin-bottom: 16px;
        transform: scale(0);
        opacity: 0;
        border-radius: 16px;
        overflow: hidden;
    }
    
    .contentThree .card .ant-image {
        width: 100%;
        border-radius: 16px;
    }
    
    .contentThree .card .card-title {
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        margin-top: 24px;
    }
    
    .contentThree .card .card-content {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(255, 255, 255, .6);
        text-align: left;
        margin-top: 8px;
    }
    
    .contentThree .card .card-buts {
        width: 100%;
        display: flex;
        margin-top: 50px;
    }
    
    .contentThree .card-buts .but {
        height: 28px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(255, 255, 255, 1);
        margin-right: 8px;
    }
    .animate {
        animation: OrotateAnimation 0.5s linear 0.1s forwards, SrotateAnimation 0.5s linear forwards;
    }
    
    @keyframes OrotateAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    
    @keyframes SrotateAnimation {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
}