@media (min-width: 360px) {
    .api-content-two {
        width: 100%;
        color: rgba(29, 33, 41, 1);
    }
    .api-content-two .content-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: start;
    }
    .api-content-two .content-title .title {
        flex: 1;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        margin-right: 24px;
    }
    .api-content-two .content-title .cont {
        width: 552px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
    .api-content-two .content-title .cont > div:nth-of-type(1) {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 24px;
    }
    .api-content-two .content-title .cont > div:nth-of-type(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgba(29, 33, 41, 1);
        border-radius: 36px;
        width: 100%;
        height: 72px;
        padding: 16px;
        margin-bottom: 8px;
    }
    .api-content-two .content-title .cont > div:nth-of-type(3) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgba(29, 33, 41, 1);
        border-radius: 36px;
        width: 100%;
        height: 72px;
        padding: 16px;
    }
    .api-content-two .cont .icon-android,.icon-apple {
        margin: 0 24px;
        font-size: 30px;
    }
    .api-content-two .cont .text {
        display: flex;
        align-items: center;
    }
    .api-content-two .content-cont {
        width: 80vw;
        margin: 0 auto;
        margin-top: 60px;
    }
    .api-content-two .content-cont .title {
        font-size: 18px;
        font-weight: 400;
        color: rgba(128, 128, 128, 1);
        text-align: center;
        line-height: 24px;
        margin-bottom: 100px;
    }
    .api-content-two .content-cont .falls-bfc {
        width: 80vw;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
    }
    .api-content-two .falls-bfc .falls-one {
        width: 39vw;
    }
    .api-content-two .falls-bfc .falls-two {
        width: 39vw;
    }

    .api-content-two .falls-bfc .box {
        width: 100%;
        border: 1px solid rgba(128, 128, 128, 1);
        border-radius: 16px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, .25);
        padding: 60px;
        margin-bottom: 60px;
    }
    .api-content-two .falls-bfc .box .icon {
        color: rgba(128, 128, 128, 1);
        font-size: 60px;
        margin-bottom: 60px;
    }
    .api-content-two .falls-bfc .box .name {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 60px;
    }
    .api-content-two .falls-bfc .box .cont {
        font-size: 18px;
        color: rgba(128, 128, 128, 1);
    }
}