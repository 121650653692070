@media (min-width: 360px) {
    .contentTwo {
        width: 80vw;
        margin: 40px auto 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .contentTwo .card {
        width: 38vw;
        position: relative;
        margin-bottom: 60px;
        overflow: hidden;
        border-radius: 20px;
    }
    .contentTwo .card img {
        width: 100%;
        border-radius: 20px;
        vertical-align: bottom;
    }
    .contentTwo .card:hover > .card-content {
        bottom: 0;
    }

    .contentTwo .card .card-content {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        position: absolute;
        bottom: -100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
        transition: bottom 500ms ease;
        color: rgba(255, 255, 255, 1);
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    .contentTwo .card .card-title {
        font-size: 20px;
        margin-left: 16px;
    }
    .contentTwo .card .card-text {
        font-size: 16px;
        margin-left: 16px;
        margin-bottom: 16px;
    }
}