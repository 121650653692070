.text-area-input .text-area-title {
    width: 100%;
    height: 50px;
}
.text-area-input .text-area-title span:nth-of-type(1) {
    font-size: 18px;
    font-weight: 500;
    line-height: 50px;
}
.text-area-input .text-area-title span:nth-of-type(2) {
    font-size: 20px;
    color: rgba(128, 128, 128, .5);
    margin-left: 10px;
}
.text-area-input .text-area-item {
    width: 100%;
    border-radius: 16px;
    border: 1px solid rgba(218, 220, 224, 1);
    padding: 11px;
}
.text-area-input .text-area-item textarea {
    min-height: 100px;
}