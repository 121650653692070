.app .ant-layout-header {
    height: initial;
    padding: 0;
    color: initial;
    line-height: initial;
    background-color: initial;
}

@media (min-width: 360px) {
    .app .layout-style {
        overflow: hidden;
    }
    .app .header-style {
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
    }
    .app .content-style {
        width: 100%;
        min-height: 120px;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        margin: auto;
    }
    .app .footer-style {
        width: 100%;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(134, 144, 156, 1);
        padding: 0;
    }
    .hover {
        position: relative;
        top: 0;
        transition: top 150ms ease;
    }
    .hover:hover {
        top: -2px;
        box-shadow: 0px 8px 4px rgba(130, 130, 130, 1);
    }
}
