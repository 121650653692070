@media (min-width: 360px) {
    .content-two {
        width: 100%;
        height: 628px;
        background-color: rgba(134, 144, 156, 1);
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        color: rgba(255, 255, 255, 1);
    }
    .content-two .input-area {
        width: 80vw;
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .content-two .input-area .input-area-title {
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        margin-top: 80px;
    }
    .content-two .input-area .input-area-content {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin-top: 16px;
    }
    .content-two .input-form {
        width: 80vw;
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .content-two .form {
        width: 560px;
    }
    .content-two .form > div {
        width: 100%;
    }
    .content-two .input-form .input-name {
        margin-top: 80px;
    }
    .content-two .input-name input {
        height: 48px;
        border-radius: 12px;
        border: none;
        font-size: 16px;
        color: rgba(255, 255, 255, .6);
        background-color: rgba(255, 255, 255, .1);
    }
    .content-two .input-form .input-email {
        margin-top: 20px;
    }
    .content-two .input-email input {
        height: 48px;
        border-radius: 12px;
        border: none;
        font-size: 16px;
        color: rgba(255, 255, 255, .6);
        background-color: rgba(255, 255, 255, .1);
    }
    .content-two .input-form .input-checked {
        margin-top: 24px;
        text-align: center;
    }
    .content-two .input-form .input-but-item{
        margin-top: 24px;
        width: 560px;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .content-two .input-form .input-but {
        width: 88px;
        height: 32px;
    }
}