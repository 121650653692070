@media (min-width: 360px) {
    .us-content-one {
        width: 100%;
        background-color: rgba(201, 205, 212, 1);
        height: 658px;
    }
    .us-content-one .bg {
        width: 2130px;
        height: 420px;
        margin: 0 auto;
        position: relative;
    }
    .us-content-one .bg .background-img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .us-content-one .bg .background {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
    }
    .us-content-one .word {
        width: 50vw;
        margin: 0 auto;
        position: relative;
        top: -336px;
    }
    
    .us-content-one .word .text {
        width: 100%;
        color: rgba(225, 225, 225, 1);
    }
    .us-content-one .word .text .text-title {
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
    }
    .us-content-one .word .text .text-content {
        font-size: 20px;
        font-weight: 400;
        line-height: 64px;
    }
    .us-content-one .word .buts {
        margin-top: 80px;
        width: 50vw;
        height: 274px;
        background-color: rgba(18, 18, 18, 1);
        border-radius: 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: rgba(225, 225, 225, 1);
    }
    .us-content-one .buts .box {
        height: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .us-content-one .buts .icon {
        font-size: 32px;
        margin-top: 38px;
    }
    .us-content-one .buts .span {
        font-size: 16px;
        font-weight: 400;
        line-height: 48px;
        color: rgba(225, 225, 225, .6);
        margin-top: 16px;
    }
    .us-content-one .buts .but {
        font-size: 16px;
        font-weight: 400;
        margin-top: 20px;
        width: 72px;
        height: 40px;
    }
    .us-content-one .content-footer {
        width: 100%;
        height: 238px;
    }
}