@media (min-width: 360px) {
    .content-one {
        width: 80vw;
        height: 44vw;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
    }
    
    .content-one .content-video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .content-one .content-title {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255,255,255, 0.3);
    }
    .content-one .content-title .text {
        font-size: 80px;
        font-weight: 700;
        color: rgba(29, 33, 41, 1);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}