.my-input .my-input-title {
    width: 100%;
    height: 50px;
}
.my-input .my-input-title span:nth-of-type(1) {
    font-size: 18px;
    font-weight: 500;
    line-height: 50px;
}
.my-input .my-input-title span:nth-of-type(2) {
    font-size: 20px;
    color: rgba(128, 128, 128, .5);
    margin-left: 10px;
}
.my-input .input {
    width: 100%;
    height: 46px;
    border-radius: 16px;
    padding-left: 20px;
}