.slider-input .slider-input-title {
    width: 100%;
    height: 50px;
}
.slider-input .slider-input-title span:nth-of-type(1) {
    font-size: 18px;
    font-weight: 500;
    line-height: 50px;
}
.slider-input .slider-input-title span:nth-of-type(2) {
    font-size: 20px;
    color: rgba(128, 128, 128, .5);
    margin-left: 10px;
}
.slider-input .slider-input-item {
    width: 93%;
    margin-left: 4%;
}