@media (min-width: 360px) {
    .api-content-three {
        width: 100%;
        color: rgba(29, 33, 41, 1);
        overflow: hidden;
    }
    .api-content-three .images {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 100px;
    }
    .api-content-three .images img {
        width: 26vw;
    }
    .api-content-three .tensor {
        display: flex;
        text-align: left;
        justify-content: space-between;
        margin-top: 160px;
        margin-bottom: 80px;
    }
    .api-content-three .tensor .left {
        font-size: 48px;
        font-weight: 700;
        line-height: 50px;
        flex: 1;
    }
    .api-content-three .tensor .right {
        width: 560px;
    }
    .api-content-three .tensor .right .text {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 24px;
    }
    .api-content-three .right .but button {
        width: 128px;
        height: 52px;
        border-radius: 16px;
        margin-right: 16px;
        font-size: 20px;
        font-weight: 400;
    }
    .api-content-three .right .but button:nth-child(1) {
        color: rgba(255, 255, 255, 1);
    }
}